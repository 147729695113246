import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { submitFormToHubspot } from "../../utils/submitFormToHubspot";

import { styled } from "../../stitches/stitches.config";
import {
  BasicElementsContainer,
  ErrorMessage,
  FormContainer,
  FormLabel,
  FormTitle,
  FormWrapper,
  InputWrapper,
  PrivacyDisclaimer,
  StyledInput,
  SubmitButton,
  FormHubspotWrapper,
} from "../shared/FormStyles";
import { Link } from "gatsby";
const title = `Schedule Discussion`;

const ContactForm = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6034553",
          formId: "66b188f5-e4cd-489b-b0f5-227153a90381",
          target: "#hubspotForm",
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    }
  }, []);


  return (
    <FormHubspotWrapperDesktop>
      <FormHubspotWrapper>
        <div id="hubspotForm" ></div>
      </FormHubspotWrapper>
    </FormHubspotWrapperDesktop>
  );
};

export default ContactForm;

const FormHubspotWrapperDesktop = styled("div", {
  "@lg": {
    marginTop: -220,
    position: "relative",
    zIndex: 1,
  },
});
