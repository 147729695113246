import React from "react";
import {
  heading200,
  heading500,
  paragraphTextLarge,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const title = `Contact our team`;
const byline = `If you have any questions about our products or services, please don't hesitate to contact us`;

const ContactHero = () => {
  return (
    <Container>
      <Title>{title}</Title>
      <Byline>{byline}</Byline>
    </Container>
  );
};

export default ContactHero;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 600,
  "@lg": {
    width: "100%",
    maxWidth: "none",
    backgroundColor: "$blue100",
    paddingBottom: 300,
    paddingTop: 157,
    textAlign: "center",
    marginTop: -80,
    position: "relative",
  },
});
const Title = styled("h1", {
  ...heading200,
  "@lg": {
    ...heading500,
    color: "$white",
  },
});
const Byline = styled("p", {
  ...paragraphTextLarge,
  "@lg": {
    color: "$white",
    width: "90%",
    margin: "0 auto",
  },
});
