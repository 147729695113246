import React from "react";
import {
  detailLarge,
  heading150,
  paragraphTextMedium,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

import Email from "./assets/email.inline.svg";
import Phone from "./assets/phone.inline.svg";

const items = [
  {
    icon: <Email />,
    title: "Email us",
    description:
      "Email us for general queries, including marketing and partnership opportunities.",
    action: <a href="mailto:info@plastometrex.com">info@plastometrex.com</a>,
  },
];
// (INT) +44 1223 867994
const ContactOptions = () => {
  return (
    <Container>
      {items.map((item) => (
        <Item key={item.title}>
          {item.icon}
          <Title>{item.title}</Title>
          <Description>{item.description}</Description>
          <Action>{item.action}</Action>
        </Item>
      ))}
    </Container>
  );
};

export default ContactOptions;

export const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 960,
  display: "flex",
  flexDirection: "column",
  padding: "71px 0",
  "@md": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
export const Item = styled("div", {
  margin: "auto",
  maxWidth: 360,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  marginBottom: 73,
  marginTop: 0,
  "& svg": {
    width: 57,
    height: 64,
  },
});
export const Title = styled("h6", {
  ...heading150,
  marginTop: 24,
  marginBottom: 12,
});
export const Description = styled("p", {
  ...paragraphTextMedium,
  margin: 0,
});
export const Action = styled("span", {
  ...paragraphTextMedium,
  fontWeight: 700,
  color: "$blue100",
});
