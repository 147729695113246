import React from "react";
import ContactForm from "../components/contact/ContactForm";
import ContactHero from "../components/contact/ContactHero";
import ContactOptions from "../components/contact/ContactOptions";
import Layout from "../components/Layout";

const seo = {
  title: "Contact Us",
  description:
    "Contact the Plastometrex team if you have any questions, if you would like to requestion a quotation, or arrange a meeting",
};

const Contact = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="blue">
      <ContactHero />
      <ContactForm />
      <ContactOptions />
    </Layout>
  );
};

export default Contact;
